export const EXTERNAL_LINKS = {
  PRIVACY_POLICY: "https://agoradata.com/privacy-policy",
  TERMS: "https://agoradata.com/terms",
  ARBITRATION_AGREEMENT: "https://agoradata.com/arbitration-agreement",
  SUPPORT_LINK: "https://support.agoradata.com/hc/en-us/requests/new",
};

export const SOCIAL_MEDIA_LINKS = {
  FACEBOOK: "https://www.facebook.com/AGORAData/",
  LINKEDIN: "https://www.linkedin.com/company/agora-data-inc.",
};
