import { SOCIAL_MEDIA_LINKS } from "@constants";
import { FacebookLogo, LinkedinLogo } from "@phosphor-icons/react";
import Link from "next/link";

export const SocialMediaLinks = () => {
  return (
    <div className="flex justify-center space-x-6">
      <Link href={SOCIAL_MEDIA_LINKS.FACEBOOK}>
        <FacebookLogo className="text-xl text-primary transition-colors hover:text-primary dark:text-gray-400" />
      </Link>
      <Link href={SOCIAL_MEDIA_LINKS.LINKEDIN}>
        <LinkedinLogo className="text-xl text-primary transition-colors hover:text-primary dark:text-gray-400" />
      </Link>
    </div>
  );
};
