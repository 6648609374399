export const LANDING_TEXTS = {
  FORM: {
    TITLE: "Sign up for free access",
    DESCRIPTION: "And receive updates and get early access to our launch.",
    SUBMITTING: "Attempting to register user...",
  },
  SUCCESS: {
    DESCRIPTION: "No further action is required.",
  },
};
