import { EXTERNAL_LINKS, LEGAL_DOCUMENTS_TEXTS } from "@constants";
import Link from "next/link";

export const LegalDocuments = () => {
  return (
    <div className="space-x-4 text-center text-sm text-gray-600 dark:text-gray-400">
      <Link href={EXTERNAL_LINKS.PRIVACY_POLICY} className="transition-colors hover:text-primary hover:underline">
        {LEGAL_DOCUMENTS_TEXTS.PRIVACY_POLICY}
      </Link>
      <Link href={EXTERNAL_LINKS.TERMS} className="transition-colors hover:text-primary hover:underline">
        {LEGAL_DOCUMENTS_TEXTS.TERMS}
      </Link>
      <Link
        href={EXTERNAL_LINKS.ARBITRATION_AGREEMENT}
        className="transition-colors hover:text-primary hover:underline"
      >
        {LEGAL_DOCUMENTS_TEXTS.ARBITRATION_AGREEMENT}
      </Link>
    </div>
  );
};
