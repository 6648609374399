const colors = {
  lowerBound: "#0D9283",
  middleBound: "#F4B94B",
  upperBound: "#C73F3F",
};

const lowerBoundName = "Low Risk";
const middleBoundName = "Medium Risk";
const upperBoundName = "High Risk";

const lowerBoundValue = 135;
const middleBoundValue = 175;
const upperBoundValue = 200;

const lowerBoundDistance = middleBoundValue - lowerBoundValue;
const middleBoundDistance = upperBoundValue - middleBoundValue;

export const LTVLimits = {
  lowerBoundName,
  middleBoundName,
  upperBoundName,
  lowerBoundValue,
  middleBoundValue,
  upperBoundValue,
  lowerBoundDistance,
  middleBoundDistance,
  colors,
};
