export const RESULTS_TEXTS = {
  SPLASH: {
    SELECT: {
      TITLE: "select a vehicle",
      DESCRIPTION: "Start with your customer and select the vehicle they love.",
    },
    BUILD: {
      TITLE: "build the deal",
      DESCRIPTION: "Fill in key details in the Deal Structure like price, down payment and APR.",
    },
    FINE_TUNE: {
      TITLE: "fine-tune for success",
      DESCRIPTION: "Adjust terms to find the perfect balance for you and your customer.",
    },
  },
  LOAN_PER: {
    TITLE: "Loan Performance",
    DESCRIPTION: "See what the estimated lifetime cash flow looks like for this loan given the current deal structure.",
  },
  DEAL: {
    AMOUNT_FINANCED: "Amount Financed",
    VEHICLE_INFO: "ALTRUVO™ Est. Value",
  },
};
