import { Button, FormField, FormLabel, Input } from "@agoradata/ads";
import React, { FC } from "react";
import { UserRole } from "@enums";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFormSubmission } from "@hooks";
import { LANDING_TEXTS } from "@constants";

// Defines the form schema  will relocate later
const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email address").min(1, "Email is required"),
  dealerName: z.string().optional(),
  role: z.union([z.nativeEnum(UserRole), z.literal("")]).optional(), // Accepts empty string as a valid value,
  isInterested: z.boolean().default(false),
});

// Infer TypeScript type from the schema
type FormData = z.infer<typeof formSchema>;

interface LandingPageFormProps {
  setIsSuccess: (success: boolean, name?: string) => void;
  requestsFocus: boolean;
}

export const LandingPageForm: FC<LandingPageFormProps> = ({ setIsSuccess, requestsFocus }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      dealerName: "",
      role: "",
      isInterested: false,
    },
  });
  const mutation = useFormSubmission();

  // Set focus when requestsFocus changes
  React.useEffect(() => {
    if (requestsFocus) {
      const element = document.querySelector("input");
      if (element) {
        element.focus();
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [requestsFocus]);

  const onSubmit = async (data: FormData) => {
    mutation.mutate(data, {
      onSuccess: () => {
        setIsSuccess(true, data.name);
      },
    });
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <form
        name="landing-page-form"
        id="landing-page-form"
        className="flex w-full flex-col items-center justify-center rounded-[30px] bg-white p-12 shadow-lg sm:w-3/4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid gap-4">
          <div className="col-span-1 flex flex-col gap-2">
            <h2 className="pb-3 pt-4 dark:text-white">{LANDING_TEXTS.FORM.TITLE}</h2>
            <p className="dark:text-white">{LANDING_TEXTS.FORM.DESCRIPTION}</p>
          </div>
          <div className="relative grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2">
            {/* Name Field */}
            <FormField label="Name*" variant="input" errors={errors.name?.message}>
              <Input register={register("name")} isError={!!errors.name} />
            </FormField>

            {/* Email Field */}
            <FormField label="Email*" variant="input" errors={errors.email?.message}>
              <Input register={register("email")} type="email" isError={!!errors.email} />
            </FormField>

            {/* Dealership Name Field */}
            <FormField label="Dealership Name" variant="input" errors={errors.dealerName?.message}>
              <Input register={register("dealerName")} />
            </FormField>
            {/* Role Selection */}
            <div className="flex flex-col gap-2">
              <FormLabel className="dark:text-white" labelText="Your Role" />
              <FormField variant="input">
                <select
                  className="h-[42px] w-full border border-grey-medium px-4 py-1 outline-none focus:ring-0 focus:ring-offset-0"
                  {...register("role")}
                >
                  <option value="">Select a role</option>
                  {Object.values(UserRole).map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </FormField>
            </div>
            <div className="col-span-1 sm:col-span-2">
              {/* Interest Checkbox */}
              <FormField
                className="w-full dark:text-white"
                label="I'm interested in participating in an early access program and providing feedback."
                variant="checkbox"
              >
                <input type="checkbox" className="dark:text-gray-700" {...register("isInterested")} />
              </FormField>
            </div>
            {/* Submit Button */}
            <div className="col-span-1 pt-10 sm:col-span-2">
              <Button text="Submit" type="submit" disabled={isSubmitting} />
            </div>
          </div>
        </div>
        {isSubmitting && <p className="pt-2 dark:text-white">{LANDING_TEXTS.FORM.SUBMITTING}</p>}
      </form>
    </div>
  );
};

export default LandingPageForm;
